import React, { Component } from 'react'
import {Link} from "react-router-dom";
import Auth from './Auth';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from  'react-loader-spinner'


class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
            isLogged : false,
            token : '',
            message : '',
            publishMessage : '',
            username : '',
            loading : false
		};
	}

    componentDidMount() {
       
        Auth.isAuthenticated((val) => {
            if(!val){
                this.setState({isLogged:false});
            }else{
                this.setState({isLogged:true});
                const objperson = JSON.parse(val);
                this.setState({token : objperson.token})
                this.setState({username : objperson.username})
            }
        });
    }
    
    logout = (e) => {
        localStorage.removeItem('user');
        window.location.reload();
    }

    save = (e) => {
        this.setState({loading:true});
        
        const url = process.env.REACT_APP_BASE_URL + '/api/save.php';
        const payload = {
            save : 1,
            token : this.state.token
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnHover : false,
                theme: "colored"
            });
            this.setState({loading:false});
        })
        .catch(err => {
            console.log(err);
            this.setState({loading:false});
        });
       
    }

    publish = (e) => {

        confirmAlert({
            title: 'Confirm to publish',
            message: 'Are you sure you want to publish your changes? Make sure to save your changes before',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    const url = process.env.REACT_APP_BASE_URL + '/api/publish.php';
                    const payload = {
                        publish : 1,
                        token : this.state.token
                    }
                    fetch(url, {
                        method : "POST",
                        body : JSON.stringify(payload)
                    })
                    .then(response => response.json())
                    .then(data => {
                        toast.success(data.msg, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnHover : false,
                            theme: "colored"
                        });
                    })
                    .catch(err => {
                        toast.error(data.msg, {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: "colored"
                        });
                    });
                }
              },
              {
                label: 'No',
                onClick: () => {

                }
              }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypressEscape: () => {}
          });
    }

    render(){
        const color = {
            color : '#fff'
        }
        const override = {
            display: 'block',
            margin: '0 20px'
        };
        return (  
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                <div className="container-fluid">
                <ToastContainer />
                <a className="navbar-brand" href="/">Promobanner</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                    {(this.props.page === 'listing') ? 
                        <li className="nav-item active">
                            <Link className="nav-link" to="/listing">Listing</Link>
                        </li>
                        :
                        <li className="nav-item">
                            <Link className="nav-link" to="/listing">Listing</Link>
                        </li>
                    }
                    {(this.props.page === 'add') ? 
                        <li className="nav-item active">
                            <Link className="nav-link" to="/add">Add new extension</Link>
                        </li>
                        :
                        <li className="nav-item">
                            <Link className="nav-link" to="/add">Add new extension</Link>
                        </li>
                    }
                    {(this.props.page === 'blog') ? 
                        <li className="nav-item active">
                            <Link className="nav-link" to="/blog">Blog</Link>
                        </li>
                        :
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog">Blog</Link>
                        </li>
                    }
                    {/* {(this.props.page === 'generate') ? 
                        <li className="nav-item active">
                            <Link className="nav-link" to="/generate">Generate JSON</Link>
                        </li>
                        :
                        <li className="nav-item">
                            <Link className="nav-link" to="/generate">Generate JSON</Link>
                        </li>
                    }
                     */}
                    </ul>
                    {(this.state.isLogged) ? 
                    <ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
                        
                        {(this.state.username === 'superadmin') ? 
                        <>
                            <li className='nav-item' style={{ marginRight:'10px'}}>

                            <button type="button" className="btn waves-effect waves-light btn-secondary" onClick={this.save}> <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={this.state.loading}
                            />save</button> 
                            </li>
                            {/* <li className='nav-item' style={{ marginRight:'10px'}}>
                                <button type="button" className="btn waves-effect waves-light btn-danger" onClick={this.publish}>Publish</button>
                            </li> */}
                            </>
                            : ''
                        }
                        {this.props.lastUpdate ? 
                            <li className='nav-item' style={{ marginRight:'10px',fontSize:'12px',color:'#fff' }}>
                                Last update : {this.props.lastUpdate}
                            </li>
                            : ''
                        }
                       
                        <li className="nav-item">
                            <div className="collapse navbar-collapse" id="navbar-list-4">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="/user.png" width="40" height="40" className="rounded-circle" style={{float:'left'}}/>
                                    <span style={{fontSize:'12px',color:'#fff',margin:'0 5px'}} className="d-none d-sm-block ms-1">{this.state.username}</span>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a className="dropdown-item" href="/#" onClick={this.logout}>Log Out</a>
                                    </div>
                                </li>   
                                </ul>
                            </div>
                        </li>
                    </ul>
                    :
                    ''
                    }
                </div>
                
                </div>
            </nav>
        );
    }
}
 
export default Navbar;
import React, { Component } from 'react'
import Navbar from '../components/navbar';
import Auth from '../components/Auth';


class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			color : '#36D7B7',
			loading : true,
			css : '',
            result : ''
		};
	}

	componentDidMount() {
       
        Auth.isAuthenticated((val) => {
            if(!val){
                this.props.history.push("/login");
            }else{
                const objperson = JSON.parse(val);
                this.setState({token : objperson.token});

                const url = process.env.REACT_APP_BASE_URL + '/api/checksession.php';
                const data = {token : objperson.token}
                fetch(url, {
                    method : "POST",
                    body : JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => this.setState({result : data.result}))
            }
        })
    }

	render(){
	
        if(this.state.result === 'nosession'){
			localStorage.removeItem('user');
			this.props.history.push("/login");
			window.location.reload();
		}

		return (
			<>
                <Navbar page="home"/>
                <div className="container-fluid">
                    <div id="outer" className="container text-center" style={{ minHeight:'100vh', display:'flex'}}>
                        <div id="inner" style={{ margin:'auto' }}>
							
                            <h4>Interface to manage theme for switch themes, hub and uninstall pages</h4>
                        </div>
                    </div>
                </div>
            </>
		);
	}
}

export default Home;

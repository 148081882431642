import React, { Component } from 'react'
import Navbar from '../components/navbar'
import { Link } from 'react-router-dom'
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import ScaleLoader from "react-spinners/ScaleLoader";
import Auth from '../components/Auth';
import $ from 'jquery'; 
import allDomains from '../components/Domains';



class Listing extends Component {
	constructor(props) {
		super(props);
		this.state = {
            extensions : [],
            color : '#007bff',
			loading : true,
			css : '',
            error : true,
			errorMessage: '',
			successMessage : '',
			show : false,
            token : '',
            nbInvalid : 0,
            nbValid : 0,
            nbTotal : '',
            domain : '',
            error : '',
            errorInfo : '',
            lastUpdate : '',
            results : ''
		};
	}

    componentDidCatch(error, errorInfo) {
        this.setState({
          error: error,
          errorInfo: errorInfo
        });
    }

  
    componentDidMount() {
       
        Auth.isAuthenticated((val) => {
            if(!val){
                this.props.history.push("/login");
            }else{
                const objperson = JSON.parse(val);
                this.setState({token : objperson.token}, function(){
                    const data = {token : objperson.token}
                    const caller = setInterval(
                        () => {
                            if($('#file_export').length > 0){
                                $('#file_export').dataTable({
                                    "order": [[ 10, "desc" ]],
                                    "dom": "lifrtp",
                                    "columnDefs": [
                                        {"className": "dt-center", "targets": "_all"}
                                      ],
                                })
                                clearInterval(caller)
                            }
                        },
                        500
                    )
                    this.populate(data);
                });
            }
        })
    }


    populate = (payload) => {
        const url = process.env.REACT_APP_BASE_URL + '/api/list.php';
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => this.setState({extensions : data.results, lastUpdate : data.lastUpdate, loading : false, nbInvalid : data.nbInvalid, nbValid : data.nbValid, nbTotal : data.nbTotal}, function(){
           
            const caller = setInterval(
                () => {
                    if($('#file_export').length > 0 ){
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
                                "dom": "lifrtp",
                                "order": [[ 10, "desc" ]]
                            }) 
                        }
                        clearInterval(caller)
                    }
                },
                500
            )
        }))
        .catch(err => {
            console.log(err);
        });
    }

    handleDomain = (event) => {
        const domain = event.target.value;
        this.setState({domain: domain})
        const payload = {
            token : this.state.token,
            domain : domain
        }
        const url = process.env.REACT_APP_BASE_URL + '/api/list.php';
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => this.setState({loading : false, nbInvalid : data.nbInvalid, nbValid : data.nbValid, nbTotal : data.nbTotal}, function(){
            var table = $('#file_export').DataTable();
            table.search( this.state.domain ).draw();
        }))
    }

    openDescription = (e, endpoint) => {
        e.preventDefault();
        document.querySelector('.modal-body').innerHTML = '';
        fetch(endpoint)
        .then(response => response.text())
        .then(response => {     
            document.querySelector('.modal-body').innerHTML = '<pre>' + response + '</pre>';
        })
        .catch(err => {
            document.querySelector('.modal-body').innerHTML = 'No description found for this extension';
            return false;
        });
    }

    delete = (event, extension, name) => {
        if(window.confirm('Do you really want to delete the extension "' + name + '"')){
            event.preventDefault();
            const endpoint = process.env.REACT_APP_BASE_URL + '/api/delete.php';
            fetch(endpoint, {
                "method": "POST",
                "body": JSON.stringify({extension : extension, token : this.state.token})
            })
            .then(response => response.json())
            .then(response => {     
                if(response.action === "failed"){
                    this.setState({errorMessage : response.msg})
                    this.setState({show : true})
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false;
                }else{
                    this.setState({successMessage : response.msg})
                    this.setState({show : true})
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    setTimeout(() => {
                        this.props.history.push("/listing");
                        window.location.reload();
                    }, 3000);
                }
            })
            .catch(err => {
                console.log(err);
            });
        }else{
            return false;
        }
    }

	render(){
      
        if(this.state.extensions === 'nosession'){
			localStorage.removeItem('user');
			this.props.history.push("/login");
			window.location.reload();
		}
        
        const showMessage = {
			display : (this.state.show) ? "block" : "none"
		}
		const showSuccess = {
			display : (this.state.successMessage !== '') ? "block" : "none"
		}
	
		const showError = {
			display : (this.state.errorMessage !== '') ? "block" : "none"
		}

        const allExts = this.state.extensions;
        return (
			<div>
                <Navbar page="listing" lastUpdate={this.state.lastUpdate}/>
                <div className="container-fluid">
                    {(this.state.loading) ? 
                        <div style={{margin : '0 auto', width: '50px'}}>
                            <ScaleLoader color={this.state.color} loading={this.state.loading} />
                        </div>
                        :
                    <>
                    <div className="card-group">
                        <div className="card p-2 p-lg-3">
                            <div className="p-lg-3 p-2">
                                <div className="d-flex align-items-center">
                                
                                <div className="ms-4" style={{width: Math.round((this.state.nbValid / this.state.nbTotal)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px' }}>Accepted({Math.round((this.state.nbValid / this.state.nbTotal)*100) + '%'})</h4>
                                    <div className="progress">
                                    <div
                                        className="progress-bar bg-success"
                                        role="progressbar"
                                        style={{width: "100%"}}
                                        aria-valuenow="40"
                                        aria-valuemin="0"
                                        aria-valuemax="40"
                                    ></div>
                                    </div>
                                </div>
                                <div className="ms-auto">
                                    <h2 className="display-7 mb-0">{this.state.nbValid}</h2>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-2 p-lg-3">
                            <div className="p-lg-3 p-2">
                                <div className="d-flex align-items-center">
                                
                                <div className="ms-4" style={{width: Math.round((this.state.nbInValid / this.state.nbTotal)*100) + '%'}}>
                                    <h4 className="fw-normal" style={{ minWidth:'160px' }}>Rejected ({Math.round((this.state.nbInvalid / this.state.nbTotal)*100) + '%'})</h4>
                                    <div className="progress">
                                    <div
                                        className="progress-bar bg-danger"
                                        role="progressbar"
                                        style={{width: "100%"}}
                                        aria-valuenow="40"
                                        aria-valuemin="0"
                                        aria-valuemax="40"
                                    ></div>
                                    </div>
                                </div>
                                <div className="ms-auto">
                                    <h2 className="display-7 mb-0">{this.state.nbInvalid}</h2>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Long Description</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row" style={showMessage}>
                        <div className="
                            alert alert-success alert-dismissible
                            bg-success
                            text-white
                            border-0
                            fade
                            show
                            " role="alert" style={showSuccess}>
                            <strong>Success - </strong> {this.state.successMessage}
                        </div>
                        <div className="
                            alert alert-danger alert-dismissible
                            bg-danger
                            text-white
                            border-0
                            fade
                            show
                            " role="alert" style={showError}>
                            <strong>Error - </strong> {this.state.errorMessage}
                        </div>
                    </div>
                    <div className='row'>
                        <div className="mb-3 form-group">
                            <label>Domain</label>
                            <div className="controls">
                                <select
                                    name="domain"
                                    id="domain"
                                    className="form-select" 
                                    onChange={this.handleDomain} 
                                    value={this.state.domain}>
                                        <option value="">All</option>
                                        {
                                            allDomains.map((item, i) => (
                                                <option value={item.name} key={i}>
                                                    {item.name}
                                                </option>
                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The Domain field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{padding:'20px'}}>
                                <div>
                                    <div className="table-responsive">
                                       
                                        <table
                                        id="file_export"
                                        className="table table-striped table-bordered display"
                                        >
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Extname</th>
                                                <th>Description</th>
                                                <th>Chrome Store Link</th>
                                                <th>Extension</th>
                                                <th>Domain</th>
                                                <th>Location</th>
                                                <th>Gallery</th>
                                                <th>Valid</th>
                                                <th>Featured</th>
                                                <th>Last update</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            allExts.map((item, i) => (
                                                <tr key={i}>
                                                    <td style={{ textAlign:'center' }}><img src={item.image.replace('www.mystart.com', 'mystart.mystartcdn.com')} alt={item.extname} /></td>
                                                    <td>{item.name}</td>
                                                    <td>{item.extname}</td>
                                                    <td>{item.description}</td>
                                                    <td><a href={item.chrome_store_link}  rel="noreferrer" target="_blank">Link</a></td>
                                                    <td>{item.extension}</td>
                                                    <td ><span className={'domain ' + item.domain.replace(/\./, '_')}>{item.domain}</span></td>
                                                    <td>
                                                        {(typeof item.location !== "undefined") ? 
                                                        <ul>
                                                        {
                                                            item.location.map((item, i) => (
                                                                <li key={i}>{item}</li>
                                                            ))
                                                        }
                                                        </ul>
                                                        : ''
                                                        }
                                                    </td>
                                                    <td><a href={item.gallery} rel="noreferrer" target="_blank">Link</a></td>
                                                    {(Boolean(item.valid) === true) ?
                                                    <td>
                                                        <button
                                                        type="button"
                                                        className="btn waves-effect waves-light btn-success"
                                                        >Yes</button>
                                                    </td>
                                                    :
                                                    <td>
                                                        <button
                                                        type="button"
                                                        className="btn waves-effect waves-light btn-danger"
                                                        >No</button>
                                                    </td>
                                                    }
                                                    {(item.featured) ?
                                                    <td>
                                                        <button
                                                        type="button" rel={item.featured}
                                                        className="btn waves-effect waves-light btn-success"
                                                        >Yes</button>
                                                    </td>
                                                    :
                                                    <td>
                                                        <button
                                                        type="button" rel={item.featured}
                                                        className="btn waves-effect waves-light btn-danger"
                                                        >No</button>
                                                    </td>
                                                    }
                                                    <td>{item.createdAt}</td>
                                                    <td>
                                                        <Link to={"/edit/" + item.extension}>
                                                        <button
                                                        type="button"
                                                        className="btn waves-effect waves-light btn-info"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#edit-modal"
                                                        >
                                                            <i className="me-2 mdi mdi-tooltip-edit">
                                                            Edit
                                                            </i>
                                                        </button>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <button
                                                        type="button"
                                                        className="btn waves-effect waves-light btn-danger"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#edit-modal"
                                                        onClick={(e) => this.delete(e, item.extension, item.name)}
                                                        >
                                                            <i className="me-2 mdi mdi-delete">
                                                            Delete
                                                            </i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Extname</th>
                                                <th>Description</th>
                                                <th>Chrome Store Link</th>
                                                <th>Extension</th>
                                                <th>Domain</th>
                                                <th>Location</th>
                                                <th>Gallery</th>
                                                <th>Valid</th>
                                                <th>Featured</th>
                                                <th>Last Update</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                        </table>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
                </div>
                </div>
		);
	}
}

export default Listing;

import React, { Component } from 'react'
import Navbar from '../components/navbar'
import { Link } from 'react-router-dom'
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import ScaleLoader from "react-spinners/ScaleLoader";
import Auth from '../components/Auth';
import $ from 'jquery'; 
import allDomains from '../components/Domains'



class Blog extends Component {
	constructor(props) {
		super(props);
		this.state = {
            extensions : [],
            color : '#007bff',
			loading : true,
            token : '',
            domain : '',
            domains : []
		};
	}

  
    componentDidMount() {
       
        Auth.isAuthenticated((val) => {
            if(!val){
                this.props.history.push("/login");
            }else{
                const objperson = JSON.parse(val);

                this.setState({token : objperson.token}, function(){
                    const payload = {token : objperson.token}
                    const url = process.env.REACT_APP_BASE_URL + '/api/getDomainsBlogs.php';
                    fetch(url, {
                        method : "POST",
                        body : JSON.stringify(payload)
                    })
                    .then(response => response.json())
                    .then(data => this.setState({domains : data.results}, function(){
                       
                    }))
                    .catch(err => {
                        console.log(err);
                    });
                });


                this.setState({token : objperson.token}, function(){
                    const payload = {token : objperson.token}
                    const url = process.env.REACT_APP_BASE_URL + '/api/getBlogs.php';
                    fetch(url, {
                        method : "POST",
                        body : JSON.stringify(payload)
                    })
                    .then(response => response.json())
                    .then(data => this.setState({extensions : data.results, loading : false}, function(){
                       
                        $('#file_export').DataTable({
                            "dom": "lifrtp",
                            "pageLength": 25
                        })  
                    }))
                    .catch(err => {
                        console.log(err);
                    });
                });
            }
        })
    }

    handleDomain = (event) => {
        const domain = event.target.value;
        console.log(domain);
        this.setState({domain: domain}, () => {
            var table = $('#file_export').DataTable();
            table.search( this.state.domain ).draw();
        })
    }

  	render(){
        if(this.state.extensions === 'nosession'){
			localStorage.removeItem('user');
			this.props.history.push("/login");
			window.location.reload();
		}
        const allExts = this.state.extensions;
        console.log(this.state.domains);
        return (
			<div>
                <Navbar page="blog"/>
                <div className="container-fluid">
                    {(this.state.loading) ? 
                        <div style={{margin : '0 auto', width: '50px'}}>
                            <ScaleLoader color={this.state.color} loading={this.state.loading} />
                        </div>
                        :
                    <>
                    <br/>
                    <div className='row'>
                        <div className="mb-3 form-group">
                            <label>Domain</label>
                            <div className="controls">
                                <select
                                    name="domain"
                                    id="domain"
                                    className="form-select" 
                                    onChange={this.handleDomain} 
                                    value={this.state.domain}>
                                        <option value="">All</option>
                                        {
                                            this.state.domains.map((item, i) => (
                                                <option value={item} key={i}>
                                                    {item}
                                                </option>
                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The Domain field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{padding:'20px'}}>
                                <div>
                                    <div className="table-responsive">
                                        <table
                                        id="file_export"
                                        className="table table-striped table-bordered display"
                                        style={{fontSize:'13px'}}
                                        >
                                        <thead>
                                            <tr>
                                                <th>Website</th>
                                                <th>Title</th>
                                                <th>Page</th>
                                                <th>Invalid Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            allExts.map((item, i) => (
                                                <tr key={i}>
                                                    <td><a href={item.site} style={{textDecoration:'none'}} target="_blank" rel="nofollow">{item.site}</a></td>
                                                    <td>{item.title}</td>
                                                    <td><a href={item.page} style={{textDecoration:'none'}} target="_blank" rel="nofollow">{item.page}</a></td>
                                                    <td><a href={item.link} style={{textDecoration:'none'}} target="_blank" rel="nofollow">{item.link}</a></td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Website</th>
                                                <th>Title</th>
                                                <th>Page</th>
                                                <th>Invalid Link</th>
                                            </tr>
                                        </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
                </div>
                </div>
		);
	}
}

export default Blog;

import React, { Component } from "react";
import Auth from "../components/Auth";
import BeatLoader from "react-spinners/BeatLoader";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
            show : false,
            error : true,
			errorMessage: '',
            username : '',
            password : '',
            color : '#fff',
			loading : false,
			css : '',
        }
    }

    componentDidMount() {
        Auth.isAuthenticated((val) => {
            if(val){
                this.props.history.push("/");
            }
        })
    }

    handleUsername = (e) => {
        this.setState({username : e.target.value})
    }

    handlePassword = (e) => {
        this.setState({password : e.target.value})
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({loading : true});
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/login.php';
        this.setState({errorMessage : ''}, function(){
            fetch(endpoint, {
                "method": "POST",
                "body": JSON.stringify({username : this.state.username, password : this.state.password})
            })
            .then(response => response.json())
            .then(response => {     
                if(response.action === "failed"){
                    this.setState({errorMessage : response.msg})
                    this.setState({show : true})
                    this.setState({loading : false})
                    return false;
                }else{
                    
                    const user = {
                        username : response.user.username,
                        token: response.user.token
                    }
                    this.setState({loading : false});
                    localStorage.setItem("user", JSON.stringify(user));  
                    setTimeout(() => {
                        this.props.history.push("/listing");
                        window.location.reload();
                    }, 1000);
                }
            })
            .catch(err => {
                console.log(err);
            });
        })
       
    }

    render() {

		const showError = {
			display : (this.state.errorMessage !== '') ? "block" : "none"
		}

        const box = {
            border:'1px solid #ccc', 
            borderRadius:'10px', 
            padding:'20px', 
            margin:'20px'
        }

        return (
            <div className="container" style={{ maxWidth:'500px' }}>
                <div style={box}>
                    <form>
                        <h3>Sign In</h3>
                        <div className="row">
                            <div className="
                                    alert alert-danger alert-dismissible
                                    bg-danger
                                    text-white
                                    border-0
                                    fade
                                    show
                                    " 
                                    role="alert"
                                    style={showError}
                                    >
                                    <strong>Error - </strong>{this.state.errorMessage}
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" className="form-control" placeholder="Enter username" onChange={this.handleUsername}/>
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" placeholder="Enter password" onChange={this.handlePassword}/>
                        </div>
                        <button type="submit" className="btn btn-primary btn-block" onClick={this.handleSubmit}>
                        <BeatLoader color={this.state.color} loading={this.state.loading} />Submit</button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;
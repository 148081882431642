
import React, { Component } from 'react'
import Navbar from '../components/navbar';
import Auth from '../components/Auth';
import allDomains from '../components/Domains';
import allThemes from '../components/Themes';
import allLocations from '../components/Locations';
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Edit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			themes: [],
            locations : [],
			extname : '',
            description : '',
			chromelink : '',
			extid : '',
			domain : '',
			gallery: '',
			name : '',
			error : true,
			errorMessage: '',
			successMessage : '',
			show : false,
            promo_img : '',
            showImage : false,
            token : '',
            valid : true,
            featured : true,
            selectedFile : '',
            isSelected : false,
            filename : '',
            showPromoImage : false,
            loading : false,
            btnStyle : 'btn-primary',
            btnText : 'Save image'
		};
	}

    componentDidMount() {
        Auth.isAuthenticated((val) => {
            if(!val){
                this.props.history.push("/login");
            }else{
                
                const objperson = JSON.parse(val);
                this.setState({token : objperson.token}, function(){
                    const id = this.props.match.params.id;
                    const url = process.env.REACT_APP_BASE_URL + '/api/getExtension.php';
                    fetch(url, {
                        method : "POST",
                        body : JSON.stringify({id : id, token : this.state.token})
                    })
                    .then(response => response.json())
                    .then(data => this.setState({
                        chromelink : data.results[0].chrome_store_link,
                        extname : data.results[0].extname,
                        description : data.results[0].description,
                        domain : data.results[0].domain,
                        extid : data.results[0].extension,
                        gallery : data.results[0].gallery,
                        promo_img : data.results[0].image,
                        name : data.results[0].name,
                        themes : data.results[0].theme,
                        locations : data.results[0].location,
                        valid : data.results[0].valid,
                        featured : data.results[0].featured,
                        showImage : true
                    }))
                    .then(function(){
                            var elements = document.querySelectorAll("form input[type=checkbox]");
                            for (var i = 0; i < elements.length; i++) {
                                if(elements[i].getAttribute('found') === "true"){
                                    elements[i].checked = true;
                                }
                            }
                        }
                    )
                    .catch(err => {
                        console.log(err);
                    });
                });
            }
        })
        
        
    }

    changeImage = (event) => {
		this.setState({selectedFile : event.target.files[0]}, () => {
            this.setState({showPromoImage : true})
            this.setState({isSelected : true})
            this.setState({filename : this.state.promo_img.substring(this.state.promo_img.lastIndexOf('/') + 1)}, () => {
                this.setState({promo_img : URL.createObjectURL(this.state.selectedFile)})
            })
        });
	};

	handleExtname = (event) => {
        this.setState({extname : event.target.value});
        if (event.target.value.length < 3) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});

        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    resetImage = () => {
        this.setState({showImage : false})
    }

    loadImage = (event) => {
        event.preventDefault();
        const newval = this.state.suffixe + '-' + this.state.extname + '.jpg';
        if(this.state.suffixe === '' || this.state.extname === ''){
            this.setState({show : true});
            this.setState({errorMessage : 'Please select a extname and a domain first'});
            return false;
        }
        this.setState({promo_img : newval}, function(){
            this.setState({showImage : !this.state.showImage})
        });
        
    }

	handleChromelink = (event) => {
		this.setState({chromelink : event.target.value})
		if (event.target.value.indexOf('https://') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}
	

    handleStatus = (event) => {
		this.setState({valid : event.target.value})
    }

    handleFeatured = (event) => {
		this.setState({featured : event.target.value})
    }

	handleExtid = (event) => {
		this.setState({extid : event.target.value})
		if (event.target.value.length < 24) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}
	checkIsValidDomain = (domain) => { 
		var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/); // eslint-disable-line
		return domain.match(re);
	} 

	handleDomain = (event) => {
		this.setState({domain : event.target.value})
		if (!this.checkIsValidDomain(event.target.value)) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}

	handleTheme = (event) => {
      
		if(event.target.checked){
            this.setState({themes : [...this.state.themes, event.target.value]})
        }else{
            event.target.checked = false;
            const index = this.state.themes.indexOf(event.target.value);
            if (index > -1) {
                this.state.themes.splice(index, 1);
            }
        }
	}

    handleLocation = (event) => {
      
		if(event.target.checked){
            this.setState({locations : [...this.state.locations, event.target.value]})
        }else{
            event.target.checked = false;
            const index = this.state.locations.indexOf(event.target.value);
            if (index > -1) {
                this.state.locations.splice(index, 1);
            }
        }
	}

	handleGallery = (event) => {
		this.setState({gallery : event.target.value})
		if (event.target.value.indexOf('https://gallery.mystartcdn.com/') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}
	handleName = (event) => {
		this.setState({name : event.target.value})
		if (event.target.value.length < 3) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}

    handleDescription = (event) => {
		this.setState({description : event.target.value})
	}

    savePromoImage = () => {
        const formData = new FormData();
        formData.append(
            "promoFile",
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          formData.append(
            "filename",
            this.state.filename
          );
          formData.append(
            "token",
            this.state.token
          );
          const endpoint = process.env.REACT_APP_BASE_URL + '/api/updatePromo.php';

          this.setState({loading : true}, () => {
            fetch(endpoint, {
                "method": "POST",
                "body": formData
            })
            .then(response => response.json())
            .then(response => {     
                this.setState({loading : false})
                this.setState({btnText : response.msg})
                if(response.action === "failed")
                    this.setState({btnStyle : 'btn-danger'})
                else
                    this.setState({btnStyle : 'btn-success'})
                setTimeout(() => {
                    this.setState({showPromoImage : false})
                }, 4000);
            })
            .catch(err => {
                console.log(err);
            });
        })
    }

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({errorMessage : ''});
        this.setState({successMessage : ''});
        const data = {
            filename : this.state.filename,
            image : this.state.promo_img,
            extname : this.state.extname,
            description : this.state.description,
            chrome_store_link : this.state.chromelink,
			extension : this.state.extid,
			domain: this.state.domain,
			theme : this.state.themes,
            location : this.state.locations,
			gallery : this.state.gallery,
			name : this.state.name,
            token : this.state.token,
            valid : this.state.valid,
            featured : this.state.featured,
            selectedFile : this.state.selectedFile
        };
	
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/update.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnHover : false,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnHover : false,
                    theme: "colored"
                });
            }
                
        })
        .catch(err => {
            console.log(err);
        });

		return false;
	}
	render(){  
        const color = {
            color : '#fff'
        }
        const override = {
            display: 'block',
            margin: '0 20px'
        }

		const bold = {
			fontWeight : '600'
		}

		const showMessage = {
			display : (this.state.show) ? "block" : "none"
		}
		const showSuccess = {
			display : (this.state.successMessage !== '') ? "block" : "none"
		}
	
		const showError = {
			display : (this.state.errorMessage !== '') ? "block" : "none"
		}

		return (
            
			<>
                <Navbar/>
                <div className="container-fluid">
                    <ToastContainer />
                    <form style={{ marginTop:'30px' }}>
                        <div className="row" style={showMessage}>
                            <div className="
                                alert alert-success alert-dismissible
                                bg-success
                                text-white
                                border-0
                                fade
                                show
                                " role="alert" style={showSuccess}>
                                <strong>Success - </strong> {this.state.successMessage}
                            </div>
                            <div className="
                                alert alert-danger alert-dismissible
                                bg-danger
                                text-white
                                border-0
                                fade
                                show
                                " role="alert" style={showError}>
                                <strong>Error - </strong> {this.state.errorMessage}
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Domain <span className="text-danger">*</span></label>
                            <div className="controls">
                                <select
                                    name="domain"
                                    id="domain"
                                    className="form-select" 
                                    onChange={this.handleDomain} 
                                    value={this.state.domain}>
                                        <option value="">Domain</option>
                                        {
                                            allDomains.map((item, i) => (
                                                <option value={item.name} key={i}>
                                                     {item.name}
                                                </option>
                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The Domain field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Extname</label>
                            <input type="text" className="form-control" id="extname" placeholder="Enter extname" value={this.state.extname} onChange={this.handleExtname} onBlur={this.resetImage} disabled/>
                            <small id="extnameHelp" className="form-text text-muted">The extname is the folder name of the extension (see worktable).</small>
                        </div>
                        
                        {(this.state.showImage) ? 
                            <div className='mb-3 form-group'>
                                <img type="file" style={{boxShadow: '2px 2px 20px 2px #888888'}} src={this.state.promo_img} alt="Promobanner" />
                                &nbsp;<input type="file" accept="image/x-png,image/jpeg"  onChange={this.changeImage}/>
                            </div>
                            :
                            ''
                        }
                        {
                            (this.state.showPromoImage) ? 
                                <button type="button" className={"btn " + this.state.btnStyle} onClick={this.savePromoImage}><ClipLoader color={color} loading={this.state.loading} style={override} size={15} />{this.state.btnText}</button>
                            : ''
                        }
                        <div className="mb-3 form-group">
                            <label style={bold}>Chrome Store Link</label>
                            <input type="text" className="form-control" id="chromelink" placeholder="Chrome store link" value={this.state.chromelink} onChange={this.handleChromelink}/>
                            <small id="chromelinkHelp" className="form-text text-muted">This info is in the worktable</small>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Description</label>
                            {/* <input type="text" className="form-control" id="description" placeholder="Enter a description" value={this.state.description} onChange={this.handleDescription} /> */}
                            <textarea className="form-control small" id="tdescription" placeholder="Enter a description" value={this.state.description} onChange={this.handleDescription}></textarea>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Extension ID</label>
                            <input type="text" className="form-control" id="extid" placeholder="Extension ID" value={this.state.extid} onChange={this.handleExtid} disabled/>
                            <small id="extidHelp" className="form-text text-muted">It's the unique ID of the extension. You can got it from the site onedev.mystart.com</small>
                        </div>
                        
                        <div className="mb-3 form-group">
                            <label style={bold}>Themes <span className="text-danger">*</span></label>        
                            <div className="controls">
                                {
                                    allThemes.map((item, i) => (
                                        (this.state.themes.includes(item)) ? 
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="form-check-input success check-outline outline-success" found="true" type="checkbox" id="success-outline-check" value={item} onChange={this.handleTheme}/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                        :
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="form-check-input success check-outline outline-success" found="false" type="checkbox" id="success-outline-check" value={item} onChange={this.handleTheme}/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                    ))
                                }
                                <div className="invalid-feedback">The field Theme is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>

                        <div className="mb-3 form-group">
                            <label style={bold}>Locations <span className="text-danger">*</span></label>        
                            <div className="controls">
                                {
                                    allLocations.map((item, i) => (
                                        (this.state.locations.includes(item)) ? 
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="form-check-input success check-outline outline-success" found="true" type="checkbox" id="success-outline-check" value={item} onChange={this.handleLocation}/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                        :
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="form-check-input success check-outline outline-success" found="false" type="checkbox" id="success-outline-check" value={item} onChange={this.handleLocation}/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                    ))
                                }
                                <div className="invalid-feedback">The field Theme is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>

                        <div className="mb-3 form-group">
                            <label style={bold}>Gallery URL <span className="text-danger">*</span></label>        
                            <div className="controls">
                                <input type="text" className="form-control" id="gallery" placeholder="Gallery URL" value={this.state.gallery} onChange={this.handleGallery}/>
                                <small id="galleryHelp" className="form-text text-muted">Get the link from https://gallerybuilder.mystart.com/</small>
                                <div className="invalid-feedback">The field Gallery is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Valid <span className="text-danger">*</span></label>
                            <div className="controls">
                                <select
                                    name="valid"
                                    id="domain"
                                    className="form-select" 
                                    onChange={this.handleStatus} 
                                    value={this.state.valid}>
                                         {this.state.valid ?
                                        <> 
                                            <option value="true" selected>Yes</option>
                                            <option value="false">No</option>
                                        </>
                                        :
                                        <>
                                            <option value="true">Yes</option>
                                            <option value="false" selected>No</option>
                                        </>
                                        }
                                </select>
                                <div className="invalid-feedback">The status field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Featured <span className="text-danger">*</span></label>
                            <div className="controls">
                                <select
                                    name="valid"
                                    id="domain"
                                    className="form-select" 
                                    onChange={this.handleFeatured} 
                                    value={this.state.featured}>
                                        {this.state.featured ?
                                        <> 
                                            <option value="true" selected>Yes</option>
                                            <option value="false">No</option>
                                        </>
                                        :
                                        <>
                                            <option value="true">Yes</option>
                                            <option value="false" selected>No</option>
                                        </>
                                        }
                                </select>
                                <div className="invalid-feedback">The featured field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Name <span className="text-danger">*</span></label>        
                            <div className="controls">
                                <input type="text" className="form-control" id="name" placeholder="Name" value={this.state.name} onChange={this.handleName}/>
                                <small id="nameHelp" className="form-text text-muted">Get the name from the worktable (column count)</small>
                                <div className="invalid-feedback">The field Name is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        
                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                        
                    </form>
                </div>
                <br/>
			</>
		);
	}
}

export default Edit;

const allDomains = [
    {
        "suffixe": "ah",
        "name" : "artshappen.com"
    },
    {
        "suffixe": "dyk",
        "name" : "didyouknownt.com"
    },
    {
        "suffixe": "fnt",
        "name" : "fantasynewtab.com"
    },
    {
        "suffixe": "ft",
        "name" : "funnytabs.co"
    },
    {
        "suffixe": "girly",
        "name" : "girly.life"
    },
    {
        "suffixe": "gl",
        "name" : "glamilife.com"
    },
    {
        "suffixe": "gt",
        "name" : "goodtimesnt.com"
    },
    {
        "suffixe": "in",
        "name" : "impressivenature.com"
    },
    {
        "suffixe": "live",
        "name" : "livenewtab.com"
    },
    {
        "suffixe": "ml",
        "name" : "mensquad.co"
    },
    {
        "suffixe": "nt",
        "name" : "mystart.com"
    },
    {
        "suffixe": "oes",
        "name" : "onlyextremesports.com"
    },
    {
        "suffixe": "ogl",
        "name" : "onlygoodlife.co"
    },
    {
        "suffixe": "pt",
        "name" : "playtiment.com"
    },
    {
        "suffixe": "sft",
        "name" : "sportsfantab.com"
    },
    {
        "suffixe": "ts",
        "name" : "topspeedmotors.co"
    },
    {
        "suffixe": "wot",
        "name" : "worldoftravel.co"
    }
];

export default allDomains;
import React, { Component } from 'react'
import Navbar from '../components/navbar';
import Auth from '../components/Auth';

class GenerateJSON extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	
	componentDidMount() {
        Auth.isAuthenticated((val) => {
            if(!val){
                this.props.history.push("/login");
            }else{
                const objperson = JSON.parse(val);
                this.setState({token : objperson.token}, function(){
                    const url = process.env.REACT_APP_BASE_URL + '/api/generateJSON.php';
                    fetch(url, {
                        method : "POST",
                        body : JSON.stringify({token : this.state.token})
                    })
                    .then(response => response.json())
                    .then(data => this.setState({extensions : data.results}, function(){
                        this.setState({loading : false})
                    }))
                    .catch(err => {
                        console.log(err);
                    });
                });
            }
        })
	}

	render(){
		if(this.state.extensions === 'nosession'){
			localStorage.removeItem('user');
			this.props.history.push("/login");
			window.location.reload();
		}
		return (
			<>
                <Navbar page="generate"/>
                <div className="container-fluid" style={{ background : '#001f3f'}}>
                    <pre style={{ color:'#fff' }}>{JSON.stringify(this.state.extensions, null, 2)}</pre>
                </div>
            </>
		);
	}
}

export default GenerateJSON;

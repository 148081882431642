const Themes = [
    'didyouknow',
    'fantasy',
    'funnytabs',
    'girly',
    'goodtimes',
    'impressivenature',
    'livenewtab',
    'mystart',
    'mensquad',
    'onlyextremesports',
    'onlygoodlife',
    'worldoftravel',
    'artshappen',
    'glamilife',
    'playtime',
    'sportsfantab',
    'topspeedmotors',
];

export default Themes;
import React from 'react';
import Home from './Pages/Home';
import Add from './Pages/Add'
import GenerateJSON from './Pages/generateJSON';
import Listing from './Pages/Listing';
import Blog from './Pages/Blog';
import Login from './Pages/Login';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Edit from './Pages/Edit';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
    return ( 
        <Router>
            <div className = "App" >
                <Switch >
                    <Route path = "/" exact component = { Home }/>  
                    <Route path = "/listing" exact component = { Listing }/> 
                    <Route path = "/blog" exact component = { Blog }/>  
                    <Route path = "/login" exact component = { Login }/>  
                    <Route path = "/add" exact component = { Add } /> 
                    <Route path = "/generate" exact component = { GenerateJSON } />
                    <Route path = "/edit/:id" exact component = { Edit } />
                </Switch> 
            </div> 
        </Router>
    );
}

export default App;
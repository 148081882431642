
import React, { Component } from 'react'
import Navbar from '../components/navbar';
import Auth from '../components/Auth';
import allDomains from '../components/Domains';
import allThemes from '../components/Themes';
import allLocations from '../components/Locations';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Add extends Component {
	constructor(props) {
		super(props);
		this.state = {
			themes: [],
            locations: [],
			extname : '',
            description : '',
			chromelink : '',
			extid : '',
			domain : '',
            domainURL : '',
            suffixe: '',
			gallery: '',
			name : '',
			error : true,
			errorMessage: '',
			successMessage : '',
			show : false,
            promo_img : '',
            showImage : false,
            token : '',
            results : ''
		};
	}

    componentDidMount() {
       
        Auth.isAuthenticated((val) => {
            if(!val){
                this.props.history.push("/login");
            }else{
                const objperson = JSON.parse(val);
                this.setState({token : objperson.token}, function(){
                    const url = process.env.REACT_APP_BASE_URL + '/api/auth.php';
                    fetch(url, {
                        method : "POST",
                        body : JSON.stringify({token : this.state.token})
                    })
                    .then(response => response.json())
                    .then(data => this.setState({results : data.results}))
                    .catch(err => {
                        console.log(err);
                    })
                })
            }
        })
    }

	handleExtname = (event) => {
        this.setState({extname : event.target.value});
        if (event.target.value.length < 3) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});

        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
    }

    resetImage = () => {
        this.setState({showImage : false})
    }
    loadImage = (event) => {
        event.preventDefault();
        const newval = this.state.suffixe + '-' + this.state.extname + '.jpg';
        if(this.state.suffixe === '' || this.state.extname === ''){
            this.setState({show : true});
            this.setState({errorMessage : 'Please select a extname and a domain first'});
            return false;
        }
        this.setState({promo_img : newval}, function(){
            this.setState({showImage : !this.state.showImage})
        });
        
    }

	handleChromelink = (event) => {
		this.setState({chromelink : event.target.value})
		if (event.target.value.indexOf('https://') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}
	
	handleExtid = (event) => {
		this.setState({extid : event.target.value})
		if (event.target.value.length < 24) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}
	checkIsValidDomain = (domain) => { 
		var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/); // eslint-disable-line
		return domain.match(re);
	} 

	handleDomain = (event) => {
        
        const words = event.target.value.split('_');
        const suffixe = words[0];
        this.setState({suffixe : suffixe})
        this.setState({domainURL : words[1]})
		this.setState({domain : event.target.value})
		if (!this.checkIsValidDomain(event.target.value)) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}

	handleTheme = (event) => {
		if(event.target.checked){
            this.setState({themes : [...this.state.themes, event.target.value]})
        }else{
            const index = this.state.themes.indexOf(event.target.value);
            if (index > -1) {
                this.state.themes.splice(index, 1);
            }
        }
	}

    handleLocation = (event) => {
		if(event.target.checked){
            this.setState({locations : [...this.state.locations, event.target.value]})
        }else{
            const index = this.state.locations.indexOf(event.target.value);
            if (index > -1) {
                this.state.locations.splice(index, 1);
            }
        }
	}

	handleGallery = (event) => {
		this.setState({gallery : event.target.value})
		if (event.target.value.indexOf('https://gallery.mystartcdn.com/') < 0) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}
	handleName = (event) => {
		this.setState({name : event.target.value})
		if (event.target.value.length < 3) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
            this.setState({error: true});
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
            this.setState({error: false});
        }
	}

    handleDescription = (event) => {
		this.setState({description : event.target.value})
	}

	reset = () => {
		this.setState({extname : ''});
		this.setState({chromelink : ''});
        this.setState({description : ''});
		this.setState({extid : ''});
		this.setState({domain : ''});
		this.setState({themes : []});
		this.setState({gallery : ''});
		this.setState({name : ''});
        this.setState({showImage : false});
        var elements = document.querySelectorAll("form input[type=checkbox]");
        for (var i = 0; i < elements.length; i++) {
                elements[i].checked = false;
        }
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({errorMessage : ''});
        this.setState({successMessage : ''});
        const data = {
            image : process.env.REACT_APP_PROMOBANNER_IMG_URL + this.state.promo_img,
            extname : this.state.extname,
            description : this.state.description,
            chrome_store_link : this.state.chromelink,
			extension : this.state.extid,
			domain: this.state.domainURL,
			theme : this.state.themes,
            location : this.state.locations,
			gallery : this.state.gallery,
			name : this.state.name,
            token : this.state.token
        };
		
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/add.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    pauseOnHover : false,
                    theme: "colored"
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
		return false;
	}
	render(){
		if(this.state.results === 'nosession'){
			localStorage.removeItem('user');
			this.props.history.push("/login");
			window.location.reload();
		}

      	const bold = {
			fontWeight : '600'
		}

		const showMessage = {
			display : (this.state.show) ? "block" : "none"
		}
		const showSuccess = {
			display : (this.state.successMessage !== '') ? "block" : "none"
		}
	
		const showError = {
			display : (this.state.errorMessage !== '') ? "block" : "none"
		}

		return (
			<>
                <Navbar page="add"/>
                <div className="container-fluid">
                    <ToastContainer />
                    <form style={{ marginTop:'30px' }}>
                        <div className="row" style={showMessage}>
                            <div className="
                                alert alert-success alert-dismissible
                                bg-success
                                text-white
                                border-0
                                fade
                                show
                                " role="alert" style={showSuccess}>
                                <strong>Success - </strong> {this.state.successMessage}
                            </div>
                            <div className="
                                alert alert-danger alert-dismissible
                                bg-danger
                                text-white
                                border-0
                                fade
                                show
                                " role="alert" style={showError}>
                                <strong>Error - </strong> {this.state.errorMessage}
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Domain <span className="text-danger">*</span></label>
                            <div className="controls">
                                <select
                                    name="domain"
                                    id="domain"
                                    className="form-select" 
                                    onChange={this.handleDomain} 
                                    value={this.state.domain}>
                                        <option value="">Domain</option>
                                        {
                                            allDomains.map((item, i) => (
                                                <option value={item.suffixe + '_' + item.name} key={i}>
                                                     {item.name}
                                                </option>
                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The Domain field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Name <span className="text-danger">*</span></label>        
                            <div className="controls">
                                <input type="text" className="form-control" id="name" placeholder="Name" value={this.state.name} onChange={this.handleName}/>
                                <small id="nameHelp" className="form-text text-muted">Get the name from the worktable (column count)</small>
                                <div className="invalid-feedback">The field Name is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Extname</label>
                            <input type="text" className="form-control" id="extname" aria-describedby="emailHelp" placeholder="Enter extname" value={this.state.extname} onChange={this.handleExtname} onBlur={this.resetImage}/>
                            <small id="extnameHelp" className="form-text text-muted">The extname is the folder name of the extension (see worktable).</small>
                        </div>
                        <div className="mb-3 form-group">
                        {(this.state.showImage) ? 
                            <button type="button" className="btn btn-info" onClick={this.loadImage}>Hide image</button>
                            :
                            <button type="button" className="btn btn-success" onClick={this.loadImage}>Show image</button>
                        }
                        </div>
                        {(this.state.showImage) ? 
                            <div className='mb-3 form-group'>
                                <img style={{boxShadow: '2px 2px 20px 2px #888888'}} src={process.env.REACT_APP_PROMOBANNER_IMG_URL + this.state.promo_img} alt="Promobanner" />
                            </div>
                            :
                            ''
                        }
                        <div className="mb-3 form-group">
                            <label style={bold}>Chrome Store Link</label>
                            <input type="text" className="form-control" id="chromelink" placeholder="Chrome store link" value={this.state.chromelink} onChange={this.handleChromelink}/>
                            <small id="chromelinkHelp" className="form-text text-muted">This info is in the worktable</small>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Description</label>
                            <input type="text" className="form-control" id="description" placeholder="Enter a description" value={this.state.description} onChange={this.handleDescription} />
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Extension ID</label>
                            <input type="text" className="form-control" id="extid" placeholder="Extension ID" value={this.state.extid} onChange={this.handleExtid}/>
                            <small id="extidHelp" className="form-text text-muted">It's the unique ID of the extension. You can got it from the site onedev.mystart.com</small>
                        </div>
                        
                        <div className="mb-3 form-group">
                            <label style={bold}>Themes <span className="text-danger">*</span></label>        
                            <div className="controls">
                                {
                                    allThemes.map((item, i) => (
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="
                                                form-check-input
                                                success
                                                check-outline
                                                outline-success
                                                " type="checkbox" id="success-outline-check" value={item} onChange={this.handleTheme}/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                    ))
                                }
                                <div className="invalid-feedback">The field Theme is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Locations <span className="text-danger">*</span></label>        
                            <div className="controls">
                                {
                                    allLocations.map((item, i) => (
                                        <div className="form-check form-check-inline" key={i}>
                                            <input 
                                                className="
                                                form-check-input
                                                success
                                                check-outline
                                                outline-success
                                                " 
                                                type="checkbox" id="success-outline-check_2" value={item} onChange={this.handleLocation}/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                    ))
                                }
                                <div className="invalid-feedback">The field location is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        <div className="mb-3 form-group">
                            <label style={bold}>Gallery URL <span className="text-danger">*</span></label>        
                            <div className="controls">
                                <input type="text" className="form-control" id="gallery" placeholder="Gallery URL" value={this.state.gallery} onChange={this.handleGallery}/>
                                <small id="galleryHelp" className="form-text text-muted">Get the link from https://gallerybuilder.mystart.com/</small>
                                <div className="invalid-feedback">The field Gallery is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                       
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.reset}
                            >
                            Reset
                        </button>
                        &nbsp;&nbsp;
                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Submit</button>
                        
                    </form>
                </div>
                <br/>
			</>
		);
	}
}

export default Add;
